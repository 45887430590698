import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import BackToTop from "../../components/ui/BackToTop";
import Header from "../../components/about/Header";
import OurVision from "../../components/about/OurVision";
import Brands from "../../components/about/Brands";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      direction={"column"}
      position="relative"
      bg={"transparent"}
      w={"100%"}
    >
      <BackToTop />

      <Header />

      <OurVision />

      <Brands />
    </Flex>
  );
}

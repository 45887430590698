import { useEffect } from "react";
import { Box, Flex, Heading, Stack, VStack } from "@chakra-ui/react";
import BackToTop from "../../components/ui/BackToTop";
import Partners from "../../components/team/Partners";

export default function TeamPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={"column"} bg={"transparent"}>
      <BackToTop />
      <Box
        m={0}
        w="100%"
        h={["65vh", null, "55vh"]}
        bgSize="cover"
        position="relative"
        bgRepeat="no-repeat"
        bgPosition={["65% 70%", null, "top"]}
        // bg={"cyan.700"}
        bg={"linear-gradient(to top, #2088BE 40%, transparent)"}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          w="100%"
          h={["65vh", null, "55vh"]}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition={["65% 70%", null, "top"]}
          zIndex={1}
        >
          <Box
            w={["100%", null, "77%"]}
            position="absolute"
            textAlign="center"
            backdropFilter="blur(2px)"
            left={["0", null, "7%"]}
            bottom={["15%", null, "20%"]}
            p={["0 16px", null, "0 0 2% 4%"]}
          >
            <VStack spacing={6} alignItems="center">
              <Stack direction="column" align="center" spacing={4}>
                <Heading
                  as="h1"
                  fontSize={["24px", null, "36px"]}
                  fontWeight="bold"
                  color="white"
                  mb={0}
                  pb={["32px", null, "42px"]}
                >
                  The people behind EmpathicAI
                </Heading>
              </Stack>
            </VStack>
          </Box>
        </Box>
      </Box>

      <Partners />
    </Flex>
  );
}

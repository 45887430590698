import { useState } from "react";
import { motion } from "framer-motion";

import {
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { EmboldedHeading } from "components/ui/helpers";

import stealthVideo from "assets/about/brands/stealth.webm";

interface NodeProps {
  text: string;
  src?: string;
  altSrc?: string;
  isCentral?: boolean;
  isActive: boolean;
  onClick: () => void;
}

const MotionText = motion(Text);

function Node({ text, src, altSrc, isCentral, isActive, onClick }: NodeProps) {
  // States
  const [isHovered, setIsHovered] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  // Handlers
  function isTextLogo(text: string) {
    return text === "Xaidi" || text === "iZola" || text === "NeuroBee";
  }

  function handleStealth() {
    setShowPopover((prev) => !prev);
  }

  return (
    <Popover
      placement="bottom"
      isLazy
      isOpen={showPopover}
      onClose={() => setShowPopover(false)}
    >
      <PopoverTrigger>
        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          borderRadius={"md"}
          w={isCentral ? "220px" : "90px"}
          h={"90px"}
          gridColumn={isCentral ? "2 / span 2" : "auto"} // "2 / span 2": spans the 2nd and 3rd columns, keeping it in the middle
          transition={"all .3s ease"}
          cursor={"pointer"}
          boxShadow={
            isActive || isHovered || isCentral
              ? `${hexToRgba("#0000ff", 0.15)} 0px 10px 36px 0px, ${hexToRgba(
                  "#00c1b4",
                  0.4
                )} 0px 0px 0px 1px`
              : "none"
          }
          borderWidth={1}
          borderColor={
            isActive || isHovered || isCentral ? "transparent" : "#cbd5e0"
          }
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => {
            if (text?.toLowerCase() === "stealth") handleStealth();
            else onClick();
          }}
        >
          {altSrc && (
            <Flex
              position={"relative"}
              align={"center"}
              justify={"center"}
              direction={isCentral ? "row" : "column"}
              gap={isCentral ? 4 : 0}
              w={isCentral ? "fit-content" : "90px"}
              h={"90px"}
            >
              <Flex
                align={"center"}
                justify={"center"}
                direction={"column"}
                gap={2}
                borderRadius={"md"}
              >
                <Image
                  key={altSrc}
                  src={isActive || isHovered || isCentral ? src : altSrc}
                  alt={text}
                  w={isTextLogo(text) ? "53px" : "35px"} // "60%" : "40%"
                  objectFit="cover"
                />
              </Flex>

              <MotionText
                fontSize={"11px"}
                color={"#425466"}
                fontWeight={"500"}
                fontFamily={"Poppins, sans-serif"}
                position={isCentral ? "relative" : "absolute"}
                opacity={isCentral ? 1 : 0}
                left={0}
                right={0}
                textAlign={"center"}
                animate={{
                  bottom: (isActive || isHovered) && !isCentral ? "4px" : "0px",
                  opacity: isActive || isHovered || isCentral ? 1 : 0,
                }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
              >
                {isCentral ? <EmboldedHeading text={text} inGraph /> : text}
              </MotionText>
            </Flex>
          )}
        </Flex>
      </PopoverTrigger>

      <PopoverContent
        w={"fit-content"}
        h={"fit-content"}
        _focus={{
          boxShadow: `${hexToRgba(
            "#0000ff",
            0.15
          )} 0px 10px 36px 0px, ${hexToRgba("#00c1b4", 0.4)} 0px 0px 0px 1px`,
        }}
        color={"#112142"}
      >
        <PopoverBody display={"flex"} alignItems={"center"} gap={3}>
          <Flex
            as={"video"}
            src={stealthVideo}
            w={"32px"}
            aspectRatio={1}
            autoPlay
            muted
          />
          <Text fontSize={"13px"} fontFamily={"Poppins, sans-serif"}>
            Launching soon..
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default Node;

import { useState, useEffect, useRef } from "react";
import { Flex, SimpleGrid, Text } from "@chakra-ui/react";

import Node from "./Node";
import AnimatedButton from "shared/AnimatedButton";

import { nodes, sections } from "./helpers";
import { EmboldedHeading } from "components/ui/helpers";

function Brands() {
  // States
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef<(HTMLDivElement | null)[]>([]);

  // Handlers
  function scrollToSection(idx: number) {
    const section = sectionsRef?.current[idx];

    if (section) {
      section.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionText = entry.target
              .getAttribute("data-text")
              ?.toLowerCase();
            if (sectionText) {
              setActiveSection(sectionText);
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <Flex
      mt={"60px"}
      gap={4}
      position={"relative"}
      h={"fit-content"}
      width={"85%"}
      mx={"auto"}
    >
      {/* details section */}
      <Flex direction={"column"} align={"center"} justify={"center"} w={"50%"}>
        {sections?.map((s, index) => (
          <Flex
            key={s?.name}
            direction={"column"}
            justify={"center"}
            h={"100vh"}
            w={"fit-content"}
            gap={8}
            ref={(el) => (sectionsRef.current[index] = el)}
            data-text={s?.name}
          >
            <EmboldedHeading
              text={s?.name}
              startColor={s?.startColor}
              stopColor={s?.stopColor}
            />
            <Text
              w={"85%"}
              fontFamily={"Poppins, sans-serif"}
              fontWeight={"600"}
              fontSize={"46px"}
              color={"gray.700"}
              lineHeight={1.2}
            >
              {s?.heading}
            </Text>
            <Text w={"85%"} color={"#425466"}>
              {s?.text}
            </Text>

            {s?.name?.toLowerCase() !== "empathicai" && (
              <AnimatedButton
                bg={"#0a2540"}
                color={"gray.200"}
                fontFamily="Poppins, sans-serif"
                fontWeight={"500"}
                fontSize={{ lg: "14px", "2xl": "16px" }}
                h={"fit-content"}
                w={"fit-content"}
                p={3}
                px={4}
                mt={6}
                borderRadius={"30px"}
                boxShadow={"0px 4px 9px #0e3e5840"}
                border={"none"}
                _hover={{ bg: "#0a2540" }}
              >
                Explore Website
              </AnimatedButton>
            )}
          </Flex>
        ))}
      </Flex>

      {/* graph section */}
      <Flex
        align={"center"}
        justify={"center"}
        w={"50%"}
        h={"100vh"}
        position={"sticky"}
        top={0}
      >
        <SimpleGrid
          columns={4}
          columnGap={"40px"}
          rowGap={"80px"}
          templateRows={"repeat(3, auto)"}
          templateColumns={"repeat(4, 90px)"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          {Array.from({ length: 11 }).map((_, index) => (
            <Node
              key={index}
              isCentral={nodes[index]?.name?.toLowerCase() === "empathicai"}
              text={nodes[index]?.name ?? ""}
              altSrc={nodes[index]?.altSrc}
              src={nodes[index]?.src}
              isActive={activeSection === nodes[index]?.name?.toLowerCase()}
              onClick={() =>
                // scroll to correspondent section
                scrollToSection(
                  sections.findIndex((s) => s?.name === nodes[index]?.name)
                )
              }
            />
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
}

export default Brands;

import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import vissionImg from "../../assets/home/whatWeDo/empathicai_vision_2.webp";

import { FaBullseye } from "react-icons/fa6";
import { TbTargetArrow } from "react-icons/tb";

interface TemplateProps {
  isVision: boolean;
  title: string;
  content: string;
}

function OurVisionTemplate({ isVision, title, content }: TemplateProps) {
  return (
    <Box
      w={{ lg: "360px", xl: "430px", "2xl": "510px" }}
      h={{ lg: "360px", xl: "430px", "2xl": "510px" }}
      bgSize="cover"
      bgPosition="center"
      objectFit={"cover"}
      objectPosition="center"
      position="relative"
      bgRepeat="no-repeat"
      bgImage={isVision ? `url(${vissionImg})` : ""}
      borderRadius={"50%"}
      overflow="hidden"
      bg={isVision ? "" : "linear-gradient(180deg, #259DD8 0%, #2A6486 100%)"}
    >
      <Flex
        bg={isVision ? "rgba(0, 0, 0, 0.4)" : ""}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        direction={"column"}
        align={"center"}
        height="100%"
      >
        <Heading
          as={"h2"}
          color="white"
          fontFamily="Poppins, sans-serif"
          lineHeight="1.3"
          fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
          w={"fit-content"}
          mb={4}
        >
          {title}
        </Heading>
        <Icon
          as={isVision ? FaBullseye : TbTargetArrow}
          boxSize={["24px", "42px"]}
          color={"white"}
        />
        <Text
          color={"white"}
          fontSize={["16px", null, "18px"]}
          fontWeight={["400", "bold"]}
          py={4}
          px={[2, null, 8]}
        >
          {content}
        </Text>
      </Flex>
    </Box>
  );
}

export default OurVisionTemplate;

import { useEffect } from "react";
import {
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  VStack,
} from "@chakra-ui/react";
import BackToTop from "../../components/ui/BackToTop";

import { FaSearch } from "react-icons/fa";

export default function InvestorPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={"column"} bg={"transparent"}>
      <BackToTop />
      <Box
        m={0}
        w="100%"
        h={["100vh", null, "100vh"]}
        bgSize="cover"
        objectFit={"cover"}
        objectPosition="center"
        position="relative"
        bgRepeat="no-repeat"
        bgPosition={["65% 70%", null, "top"]}
        // bg={"cyan.700"}
        bg={"#7084bc"}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          w="100%"
          h={["100vh", null, "100vh"]}
          bgSize="cover"
          objectFit={"cover"}
          objectPosition="center"
          bgRepeat="no-repeat"
          bgPosition={["65% 70%", null, "top"]}
          bgImage={
            "url(https://empathicai.s3.eu-central-1.amazonaws.com/lady_with_beaker_2.png)"
          }
          // bgImage={
          //   "url(https://empathicai.s3.eu-central-1.amazonaws.com/girl_with_test_tube.png)"
          // }
          zIndex={1}
        >
          <Box
            w={["100%", null, "77%"]}
            position="absolute"
            textAlign="center"
            backdropFilter="blur(2px)"
            left={["0", null, "7%"]}
            bottom={["15%", null, "35%"]}
            p={["0 16px", null, "0 0 2% 4%"]}
          >
            <VStack spacing={6} alignItems="center" justifyContent="center">
              <Stack direction="column" align="center" spacing={4}>
                <Heading
                  as="h1"
                  fontSize={["24px", null, "36px"]}
                  fontWeight="bold"
                  color="white"
                  mb={0}
                  pb={["32px", null, "42px"]}
                >
                  Enter code to get PDF copy
                </Heading>
                <FormControl>
                  <InputGroup>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      borderWidth="1px"
                      borderRadius={"xl"}
                      bg="white"
                      width={"100%"}
                    >
                      <Input
                        placeholder="Code, e.g. FGAXYM"
                        bg={"transparent"}
                        size="lg"
                        width={"100%"}
                        borderTopLeftRadius={"xl"}
                        borderBottomLeftRadius={"xl"}
                        borderTopRightRadius={"none"}
                        borderBottomRightRadius={"none"}
                        border={"none"}
                      />
                      <InputRightAddon
                        pointerEvents="none"
                        color={"highlight.primary"}
                        borderWidth="0"
                        borderTopLeftRadius={"none"}
                        borderBottomLeftRadius={"none"}
                        borderTopRightRadius={"xl"}
                        borderBottomRightRadius={"xl"}
                        h={"100%"}
                      >
                        <FaSearch />
                      </InputRightAddon>
                    </Flex>
                  </InputGroup>
                </FormControl>
              </Stack>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

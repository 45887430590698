import empathicImg from "assets/about/brands/solid/empathic.svg";
import empathicOutlinedImg from "assets/about/brands/outline/empathicOutlined.svg";

import vitafluenceImg from "assets/about/brands/solid/vitafluence.svg";
import vitafluenceOutlinedImg from "assets/about/brands/outline/vitafluenceOutlined.svg";

import stealthImg from "assets/about/brands/solid/stealth.svg";
import stealthOutlinedImg from "assets/about/brands/outline/stealthOutlined.svg";

import lakesAIImg from "assets/about/brands/solid/lakesAI.svg";
import lakesAIOutlinedImg from "assets/about/brands/outline/lakesAIOutlined.svg";

import moleculeLakeImg from "assets/about/brands/solid/moleculeLake.svg";
import moleculeLakeOutlinedImg from "assets/about/brands/outline/moleculeLakeOutlined.svg";

import iZolaImg from "assets/about/brands/solid/izola.svg";
import iZolaOutlinedImg from "assets/about/brands/outline/izolaOutlined.svg";

import xaidiImg from "assets/about/brands/solid/xaidi.svg";
import xaidiOutlinedImg from "assets/about/brands/outline/xaidiOutlined.svg";

import neurobeeImg from "assets/about/brands/solid/neurobee.svg";
import neurobeeOutlineImg from "assets/about/brands/outline/neurobeeOutline.svg";

interface Section {
  name: string;
  heading: string;
  text: string;
  url?: string;
  startColor?: string;
  stopColor?: string;
}

interface Node {
  name: string;
  src: string;
  altSrc: string;
  startColor?: string;
  stopColor?: string;
}

export const nodes: Node[] = [
  {
    name: "Vitafluence",
    src: vitafluenceImg,
    altSrc: vitafluenceOutlinedImg,
    startColor: "#2198D0",
    stopColor: "#313283",
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
    startColor: "#04B5BD",
    stopColor: "#112142",
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
    startColor: "#04B5BD",
    stopColor: "#112142",
  },
  {
    name: "iZola",
    src: iZolaImg,
    altSrc: iZolaOutlinedImg,
    startColor: "#6b61e8",
    stopColor: "#f2da5e",
  },
  {
    name: "LakesAI",
    src: lakesAIImg,
    altSrc: lakesAIOutlinedImg,
    startColor: "#00bfb2",
    stopColor: "#ff2c55",
  },
  {
    name: "EmpathicAI",
    src: empathicImg,
    altSrc: empathicOutlinedImg,
  },
  {
    name: "Xaidi",
    src: xaidiImg,
    altSrc: xaidiOutlinedImg,
    startColor: "#aba7f8",
    stopColor: "#5d5bd6",
  },
  {
    name: "MoleculeLake",
    src: moleculeLakeImg,
    altSrc: moleculeLakeOutlinedImg,
    startColor: "#0000ff",
    stopColor: "#00c1b4",
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
    startColor: "#04B5BD",
    stopColor: "#112142",
  },
  {
    name: "Stealth",
    src: stealthImg,
    altSrc: stealthOutlinedImg,
    startColor: "#04B5BD",
    stopColor: "#112142",
  },
  {
    name: "NeuroBee",
    src: neurobeeImg,
    altSrc: neurobeeOutlineImg,
    startColor: "#f5d804",
    stopColor: "#000000",
  },
];

export const sections: Section[] = [
  {
    name: "EmpathicAI",
    heading: "Ethical AI venture builder pioneering health solutions",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
  },
  {
    name: "Vitafluence",
    heading: "Duis aute irure dolor in reprehe",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#313283",
    stopColor: "#2198D0",
  },
  {
    name: "LakesAI",
    heading: "Excepteur sint occaecat cupidatat",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#00bfb2",
    stopColor: "#ff2c55",
  },
  {
    name: "MoleculeLake",
    heading: "Reprehenderit in voluptate velit esse cillum",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#0000ff",
    stopColor: "#00c1b4",
  },
  {
    name: "iZola",
    heading:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#6b61e8",
    stopColor: "#f2da5e",
  },
  {
    name: "Xaidi",
    heading: "Laboris nisi ut aliquip ex ea commodo consequat",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#5d5bd6",
    stopColor: "#aba7f8",
  },
  {
    name: "NeuroBee",
    heading: "Culpa qui officia deserunt mollit anim id est laborum",
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, aperiam, ipsa dignissimos fugit velit cum facilis necessitatibus natus assumenda debitis oluptatem maiores              repudiandae omnis! Quod atque assumenda expedita libero molestias?",
    url: "",
    startColor: "#f5d804",
    stopColor: "#000000",
  },
];

import { Flex } from "@chakra-ui/react";
import OurVisionTemplate from "./OurMissionTemplate";

function OurVision() {
  return (
    <Flex
      w={"85%"}
      maxW={"1200px"}
      mx={"auto"}
      gap={10}
      position={"relative"}
      justify={"space-between"}
      align={"center"}
    >
      <OurVisionTemplate
        isVision={true}
        title={"Our Vision"}
        content={
          "To revolutionize the tech industry with innovative solutions that empower communities and drive positive change."
        }
      />

      <OurVisionTemplate
        isVision={false}
        title={"Our Mission"}
        content={
          "Deliver cutting-edge technology that simplifies life and fosters growth, while maintaining a strong community focus."
        }
      />
    </Flex>
  );
}

export default OurVision;
